<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <!-- <el-form-item>
          <el-button type="success" @click="searchClick">查询</el-button>
        </el-form-item> -->

      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
            >编辑</el-button
          >
          <el-button @click="handleGroup(scope.row)" style="margin-left: 10px"
            >分组</el-button
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleDelete(scope.row._id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="view_type == 'add' ? '新增赛段管理' : '修改赛段管理'"
      :visible.sync="bannerVisible"
      v-if="bannerVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="1100px"
    >
      <el-form
        v-loading="loading"
        label-width="200px"
        :model="dataForm"
        :rules="rules"
        ref="dataForm"
        class="demo-ruleForm"
      >
        <el-form-item
          id="season_name"
          label-width="200px"
          label="赛段名称"
          prop="season_name"
          style="margin-bottom: 35px"
        >
          <el-col :span="6">
            <el-input
              ref="season_name"
              v-model="dataForm.season_name"
              maxlength="8"
              placeholder="请输入赛段名称"
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="赛段时间" required label-width="200px">
          <el-col :span="5">
            <el-form-item prop="start_time" id="match_time.match_start">
              <el-date-picker
                :readonly="has_stage"
                ref="match_time_match_start"
                type="datetime"
                placeholder="选择赛段开始时间"
                v-model="dataForm.start_time"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1" style="text-align: center">-</el-col>
          <el-col :span="5" id="match_time.match_end">
            <el-form-item prop="end_time">
              <el-date-picker
                :readonly="has_stage"
                ref="match_time_match_end"
                type="datetime"
                placeholder="选择赛段结束时间"
                v-model="dataForm.end_time"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item
          id="session_config.week_day"
          label-width="200px"
          label="默认每周比赛日期"
          prop="session_config.week_day"
          style="margin-bottom: 35px"
        >
          <el-checkbox-group
            :disabled="has_stage"
            v-model="dataForm.session_config.week_day"
          >
            <el-checkbox
              v-for="item in [1, 2, 3, 4, 5, 6, 7]"
              :label="item"
              :key="`weekday_${item}`"
              >{{ `星期${item == 7 ? "日" : item}` }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item
          id="session_config.start_time"
          label-width="200px"
          label="默认场次生成时间"
          prop="session_config.start_time"
          style="margin-bottom: 35px"
        >
          <el-col :span="6">
            <el-time-picker
              :readonly="has_stage"
              v-model="dataForm.session_config.start_time"
              :picker-options="{
                selectableRange: '00:00:00 - 23:59:59',
              }"
              value-format="HH:mm:ss"
              placeholder="默认场次生成时间"
            >
            </el-time-picker>
          </el-col>
        </el-form-item>
        <el-form-item
          id="session_config.diff"
          label-width="200px"
          label="每场间隔时间（分钟）"
          prop="session_config.diff"
          style="margin-bottom: 35px"
        >
          <el-col :span="6">
            <el-input
              :readonly="has_stage"
              ref="session_config_diff"
              type="number"
              v-model="dataForm.session_config.diff"
              maxlength="8"
              placeholder="请输入每场间隔时间"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item
          id="session_config.duration"
          label-width="200px"
          label="每场比赛持续时间（分钟）"
          prop="session_config.duration"
          style="margin-bottom: 35px"
        >
          <el-col :span="6">
            <el-input
              :readonly="has_stage"
              ref="session_config_duration"
              type="number"
              v-model="dataForm.session_config.duration"
              maxlength="8"
              placeholder="请输入每场比赛持续时间"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item
          id="match_count"
          required
          label-width="200px"
          label="每日几场"
          prop="session_config.match_count"
          style="margin-bottom: 35px"
        >
          <el-col :span="6">
            <el-input
              :readonly="has_stage"
              ref="match_count"
              type="number"
              v-model="dataForm.session_config.match_count"
              maxlength="8"
              placeholder="请输入每日几场"
            ></el-input>
          </el-col>
        </el-form-item>

        <el-form-item style="margin-top: 50px;">
          <perButton
            type="primary"
            perm="armory:reporter:save"
            @click="submitForm()"
            size="large"
            v-loading="loading"
            >提交</perButton
          >
          <el-button size="large" @click="bannerVisible = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { randomStr } from "@/utils/randomStr";
import { MessageBox } from "element-ui";
import perButton from "@/components/perm/perButton";
export default {
  name: "s1_banners",
  computed: {
    ...mapState("match", ["match_types"]),
  },
  components: {
    perButton,
  },
  data() {
    let years = [];
    for (
      let i = new Date().getFullYear() + 1;
      i >= new Date().getFullYear() - 10;
      i--
    ) {
      years.push(i);
    }

    let self = this;

    const matchEndTimeCheckRule = (rule, value, callback) => {
      if (self.dataForm.start_time != null && self.dataForm.end_time != null) {
        let match_start = new Date(self.dataForm.start_time);
        let match_end = new Date(self.dataForm.end_time);
        if (match_end <= match_start) {
          callback(new Error("结束时间必须大于开始时间"));
          return;
        }
      }

      callback();
    };

    return {
      matchType: "",
      pager: {
        total: 10,
        pageNum: 1,
      },
      ids: [],
      type: "",
      match_id: "",
      loading: false,
      searchForm: {},
      dataTable: [],
      dataList: [
        {
          prop: "season_name",
          label: "赛事名",
          width: 300,
        },
        {
          prop: "start_time",
          label: "赛段开始时间",
          width: 300,
          formatter: this.timeFormat,
        },
        {
          prop: "end_time",
          label: "赛段结束时间",
          width: 300,
          formatter: this.timeFormat,
        },
        // {
        //   prop: "status",
        //   label: "当前状态",
        //   width: 200,
        //   formatter: (row) => {
        //     switch (row.status) {
        //       case 1:
        //         return "正常";
        //       case 0:
        //         return "回收站";
        //       default:
        //         return "";
        //     }
        //   },
        // },
      ],
      bannerVisible: false,
      bannerData: {
        view_type: "add",
        _id: "",
        sort: "",
        banner_url: "",
      },
      match: false,
      view_type: "edit",
      years: years,
      has_stage: false,
      org_rule: "",
      org_prize: "",
      dataForm: {
        _id: "",
        match_id: "",
        session_config: {
          week_day: [],
          start_time: "",
          diff: "",
          duration: "",
          match_count: "",
        },
        season_name: "",
        end_time: "",
        start_time: "",
      },
      rules: {
        season_name: [
          {
            required: true,
            message: "请选择赛段名称",
            trigger: ["change", "blur"],
          },
          {
            min: 1,
            max: 8,
            message: "长度在 " + 1 + " 到 8个字符",
            trigger: ["blur", "change"],
          },
        ],
        start_time: [
          {
            type: "date",
            required: true,
            message: "请选择赛段开始日期",
            trigger: ["change", "blur"],
          },
          { validator: matchEndTimeCheckRule, trigger: ["change", "blur"] },
        ],
        end_time: [
          {
            type: "date",
            required: true,
            message: "请选择赛段结束日期",
            trigger: ["change"],
          },
          { validator: matchEndTimeCheckRule, trigger: ["change"] },
        ],
        "session_config.week_day": [
          {
            type: "array",
            required: true,
            message: "请选择每周比赛日期",
            trigger: ["change", "blur"],
          },
        ],
        "session_config.start_time": [
          {
            required: true,
            message: "请填写场次生成时间",
            trigger: ["change", "blur"],
          },
        ],
        "session_config.diff": [
          {
            required: true,
            message: "请填写场次间隔时间",
            trigger: ["change", "blur"],
          },
          {
            validator: this.validatorInteger0,
            message: "请输入大于或等于0的整数",
            trigger: ["change", "blur"],
          },
        ],
        "session_config.duration": [
          {
            required: true,
            message: "请填写每场比赛持续时间",
            trigger: ["change", "blur"],
          },
          {
            validator: this.validatorInteger,
            message: "请输入大于0的整数",
            trigger: ["change", "blur"],
          },
        ],
        "session_config.match_count": [
          {
            required: true,
            message: "请填写每日几场",
            trigger: ["change", "blur"],
          },
          {
            validator: this.validatorInteger,
            message: "请输入大于0的整数",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  methods: {
    timeFormat(row, column, cellValue, index) {
      if (cellValue && cellValue.length) {
        return new Date(cellValue).format("yyyy/MM/dd hh:mm:ss");
      } else {
        return cellValue;
      }
    },
    //规则校验
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    // 添加初始化
    add() {
      this.view_type = "add";
      this.bannerVisible = true;
      this.has_stage = false;
      this.initData();
    },
    //编辑赋值
    handleEdit(row) {
      this.view_type = "edit";
      this.has_stage = true;
      this.bannerVisible = true;
      this.dataForm._id = row._id;
      this.dataForm.start_time = new Date(row.start_time);
      this.dataForm.end_time = new Date(row.end_time);
      this.dataForm.session_config = row.session_config;
      this.dataForm.season_name = row.season_name;

      this.setFormData();
    },
    setFormData() {
      //如果没拿到数据。则代表为新建
      if (
        JSON.stringify(this.dataForm) != "{}" &&
        JSON.stringify(this.dataForm) != undefined
      ) {
        if (this.dataForm.length != 0) {
          let data = this.dataForm;
          Object.keys(this.dataForm).forEach((key) => {
            if (data[key]) {
              this.dataForm[key] = data[key];
            }
          });

          this.dataForm.session_config.start_time = new Date(
            "2022/1/1 " + (this.dataForm.session_config.start_time || "")
          );
          this.dataForm.start_time = this.getDateTime(this.dataForm.start_time);
          this.dataForm.end_time = this.getDateTime(
            this.dataForm.end_time || null
          );
        }
      } else {
        this.initData();
      }
    },
    getDateTime(dataStr) {
      if (dataStr != null) {
        return new Date(dataStr);
      }

      return null;
    },
    //分组
    handleGroup(row) {
      this.$router.push({
        path: `/match/info/info_s1/gruopLeague`,
        query: {
          match_id: this.match_id,
          type_id: this.type,
          season_id: row._id,
        },
      });
    },
    //删除
    async handleDelete(_id) {
      try {
        await MessageBox.confirm("是否删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        const { code } = await this.$http.match.delete_match_season(
          {
            _id: _id,
          },
          this.type
        );
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.handleQuery();
        }
      } catch (error) {
        console.log(error);
      }
    },
    //查询按钮
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.handleQuery();
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      this.loading = true;
      //请求接口
      const { data, errorCode } = await this.$http.match.match_season_list({
        pageNum,
        match_id: this.match_id,
      });
      this.loading = false;
      this.dataTable = data.data;
      this.pager = data.pager;
    },

    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    validatorInteger0(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于或等于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) >= 0) {
        callback();
      } else {
        callback(new Error("请输入大于或等于0的整数"));
      }
    },
    validatorInteger2(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入整数"));
      }

      if (Number.isInteger(Number(value))) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    },
    getDateTime(dataStr) {
      if (dataStr != null) {
        return new Date(dataStr);
      }

      return null;
    },
    //废弃
    initData() {
      this.dataForm = {
        _id: "",
        match_id: "",
        session_config: {
          week_day: [],
          start_time: "",
          diff: "",
          duration: "",
          match_count: "",
        },
        season_name: "",
        end_time: "",
        start_time: "",
      };
    },
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        if (valid) {
          let d = this.getSaveData();
          d.session_config.match_count = Number(d.session_config.match_count);
          //删除对象中的createTime
          delete d.createTime;
          let { code } = await this.$http.match.save_match_season(d, this.type);
          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.bannerVisible = false;
            this.handleQuery();
          }
        } else {
          console.log("error submit!!");
          this.bannerVisible = true;
        }
      });
    },
    getSaveData() {
      let start_time = this.dataForm.session_config.start_time;
      try {
        let date = new Date(this.dataForm.session_config.start_time);
        if (date instanceof Date && !isNaN(date.getTime())) {
          start_time = new Date(this.dataForm.session_config.start_time).format(
            "hh:mm:ss"
          );
        }
      } catch (e) {
        console.log(e);
      }
      let data = {
        ...this.dataForm,
        session_config: {
          ...this.dataForm.session_config,
          start_time: start_time,
        },
        start_time: this.dataForm.start_time,
        end_time: this.dataForm.end_time,
        match_id: this.match_id,
      };

      console.log(data);
      return data;
    },
    resetForm() {
      this.$refs.dataForm.resetFields();
    },
    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async activated() {
    // this.setFormData();

    this.matchType = localStorage.getItem("matchType");
    this.type = this.$route.query.type_id;
    this.match_id = this.$route.query._id;
    //查询列表
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>
